import {Component, Inject} from '@angular/core';
import {MatButton} from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle
} from "@angular/material/dialog";
import {MatCheckbox} from "@angular/material/checkbox";
import {FormsModule} from "@angular/forms";
import {DialogData} from "../../shared/models/dialog-data";

@Component({
  selector: 'nbd-dialog-finalize-data',
  standalone: true,
  imports: [
    MatButton,
    MatDialogActions,
    MatDialogContent,
    MatDialogTitle,
    MatDialogClose,
    MatCheckbox,
    FormsModule
  ],
  templateUrl: './dialog-finalize-data.component.html',
  styleUrl: './dialog-finalize-data.component.scss'
})
export class DialogFinalizeDataComponent {
  confirms= {
    company: false,
    users: false,
    gadget: false
  }
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {

  }
}
