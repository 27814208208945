import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  exhaustMap,
  Observable,
  of,
  OperatorFunction,
  ReplaySubject,
  Subject,
  switchMap,
  tap
} from 'rxjs';
import {ConfirmationDialogComponent} from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import {InfoDialogComponent} from './components/dialogs/info-dialog/info-dialog.component';
import {ErrorDialogComponent} from './components/dialogs/error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map, shareReplay} from 'rxjs/operators';
import {ToolbarComponentData} from '../core/classes/toolbar-component-data';
import {catchHttpErrorOrDefault} from './rxjs/catchHttpErrorOrDefault';
import {httpErrorMessageHandler} from './classes/ErrorMessageHandler';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SaveQueryDialogComponent} from './components/dialogs/save-query-dialog/save-query-dialog.component';
import {SelectDialogData} from './models/select-dialog-data';
import {SelectDialogComponent} from './components/dialogs/select-dialog/select-dialog.component';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {isTrue} from './rxjs/isTrue';
import {DialogFinalizeDataComponent} from "../sgr/dialog-finalize-data/dialog-finalize-data.component";

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  confirmData$ = new Subject<number>();

  toolbarText$ = new ReplaySubject<{ title: string; subtitle: string }>(1);
  showBackBtn$ = new BehaviorSubject<boolean>(false);
  backBtnFallback?: string[];

  toolbarComponent$ = new BehaviorSubject<ToolbarComponentData | null>(null);

  refSgr$: Observable<{ id: number; name: string; finalized: boolean } | null>;

  readonly$ = new BehaviorSubject<boolean>(false);

  constructor(private dialog: MatDialog,
              private breakpointObserver: BreakpointObserver,
              private auth: AuthService,
              private http: HttpClient,
              private snackBar: MatSnackBar) {

    this.refSgr$ = this.auth.user$.pipe(
      switchMap((user) => {
        if (user && user.role === 'crm-sgr') {
          return this.http.get<{ id: number; name: string; finalized: boolean }>(`${environment.apiUrl}/crm/sgr/my`);
        }
        return of(null);
      }),
      tap((sgr) => {
        if (sgr && sgr.finalized) {
          this.readonly$.next(true);
        } else {
          this.readonly$.next(false);
        }
      }),
      shareReplay(1)
    );

    this.confirmData$.pipe(
      exhaustMap((sgrId) => this.dialog.open(DialogFinalizeDataComponent, {
        minWidth: '400px',
        maxWidth: '900px',
        width: '100%',
      }).afterClosed().pipe(
        isTrue(),
        tap(() => this.readonly$.next(true)),
        switchMap(() => this.http.post<any>(`${environment.apiUrl}/crm/sgr/my/finalize`, {}).pipe(
          this.httpErrorHandler(null)
        )),
        tap(() => window.location.reload())
      ))
    ).subscribe();

  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  public confirm(title: string, message: string): Observable<boolean> {
    return this.dialog.open(ConfirmationDialogComponent, {
      minWidth: '400px',
      maxWidth: '900px',
      width: '100%',
      data: {title, message}
    }).afterClosed();
  }

  public information(title: string, message: string, action = 'OK'): Observable<boolean> {
    return this.dialog.open(InfoDialogComponent, {
      minWidth: '400px',
      maxWidth: '900px',
      width: '100%',
      data: {title, message, action}
    }).afterClosed();
  }

  public error(title: string, message: string): Observable<boolean> {
    return this.dialog.open(ErrorDialogComponent, {
      minWidth: '400px',
      maxWidth: '900px',
      width: '100%',
      data: {title, message}
    }).afterClosed();
  }

  public saveQuery(name?: string): Observable<string> {
    return this.dialog.open(SaveQueryDialogComponent, {
      maxWidth: '400px',
      width: '100%',
      data: {
        name
      }
    }).afterClosed();
  }

  public selectDialog(data: SelectDialogData): Observable<any | null> {
    return this.dialog.open(SelectDialogComponent, {
      maxWidth: '400px',
      width: '100%',
      data
    }).afterClosed();
  }

  public log(data: any) {
    console.log(data);
  }

  public showSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000
    })
  }

  public httpErrorHandler<T, R>(defaultValue: R): OperatorFunction<T, T | R> {
    return catchHttpErrorOrDefault(defaultValue, (err) =>
      this.error('Errore', httpErrorMessageHandler(err))
    );
  }

  public showSuccessSnackbar() {
    this.showSnackBar('Dati salvati con successo!', 'OK')
  }
}
