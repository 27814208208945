<h1 mat-dialog-title>Salva filtro</h1>
<div mat-dialog-content>
  <p>Inserisci un nome per questo filtro:</p>
  <mat-form-field appearance="outline" class="w-full">
    <mat-label>Nome filtro</mat-label>
    <input matInput [(ngModel)]="name">
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="null">Annulla</button>
  <button mat-flat-button [mat-dialog-close]="name" cdkFocusInitial color="primary" >Ok</button>
</div>
