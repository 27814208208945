import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, distinctUntilChanged, Subject, switchMap, tap, throwError} from 'rxjs';
import {User} from './classes/user';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {LoginData} from './classes/login-data';
import {ActivatedRoute, Router} from '@angular/router';
import {debug} from '../shared/rxjs/debug';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject<User | null>(null);
  logout$ = new Subject<void>();

  constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute) {
    this.user$.pipe(
      distinctUntilChanged(),
      debug('User'),
      tap((user) => {

        // if(user && .)
        // this.router.navigate(['/']);
      })
    ).subscribe();

    this.user$.next(JSON.parse(sessionStorage.getItem('currentUser') ?? 'null'));

    this.logout$.pipe(
      switchMap(() => this.http.post<User>(`${environment.apiUrl}/auth/logout`, {}, {withCredentials: true})
        .pipe(
          tap(() => {
            this.user$.next(null);
            sessionStorage.removeItem('currentUser');
            location.reload();
          })
        ))
    ).subscribe();

  }

  login(data: LoginData) {
    return this.http.post<User>(`${environment.apiUrl}/auth/login`, data, {withCredentials: true})
      .pipe(
        map(user => {
          if (user && user.token) {
            sessionStorage.setItem('currentUser', JSON.stringify(user));
            this.user$.next(user);
          }
          return user;
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
          }

          return throwError(() => error.error);
        })
      );
  }

  role() {
    return this.user$.value?.role ?? '';
  }
}
