<h1 mat-dialog-title>CONFERMA DATI SGR</h1>
<div mat-dialog-content>
  <p>Vuoi confermare i dati inseriti?</p>
  <p><strong>ATTENZIONE!</strong></p>
  <p>Alla conferma verrà inviata una notifica alla Segreteria Organizzativa, e non potrai più modificare i dati autonomamente.</p>

  <div class="flex flex-col">
    <mat-checkbox class="" [(ngModel)]="confirms.company">Confermi di aver inserito i dati della Società?</mat-checkbox>
    <mat-checkbox class="" [(ngModel)]="confirms.users">Confermi di aver inserito tutti gli Utenti?</mat-checkbox>
<!--    <mat-checkbox class="" [(ngModel)]="confirms.gadget">Confermi di aver inserito tutti gli eventuali Gadget?</mat-checkbox>-->

    <p class="mt-3"><b>Ti ricordiamo che per inserire i dati relativi ai Gadget hai tempo fino al 23 agosto</b></p>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button [mat-dialog-close]="true" mat-button [disabled]="!confirms.company || !confirms.users ">Conferma</button>
  <button [mat-dialog-close]="false" color="primary" mat-raised-button>Annulla</button>
</div>
