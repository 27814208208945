export const environment = {
  // dev
  // apiUrl: 'https://g6w4w095pj.execute-api.eu-south-1.amazonaws.com/prod',
  // massMailingUrl: 'https://2hpinat6s5nug6lgmnazvjl4na0pawvr.lambda-url.eu-south-1.on.aws',

  // prod
  apiUrl: 'https://2hjsalay78.execute-api.eu-south-1.amazonaws.com/prod',
  massMailingUrl: 'https://4u6sxke4axmijhyh62eazqhxke0muxrh.lambda-url.eu-south-1.on.aws',

  // staging
  // apiUrl: 'https://ks7pvxoqk8.execute-api.eu-south-1.amazonaws.com/prod',
  // massMailingUrl: 'https://4u6sxke4axmijhyh62eazqhxke0muxrh.lambda-url.eu-south-1.on.aws',

  assetsBucketUrl: 'https://dfli0lfdva1fm.cloudfront.net',
  socketUrl: 'https://bancageneralievents.org/web-socket',
  clientUrl: 'https://d3j4remq6jgic1.cloudfront.net',
  enableDebug: true
};
