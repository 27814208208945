<div class="absolute right-4 top-4">
  <mat-icon color="warn">warning</mat-icon>
</div>
<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p [innerHTML]="data.message | safe:'html'"></p>
</div>
<div mat-dialog-actions align="end">
  <button [mat-dialog-close]="true" color="warn" mat-raised-button>{{data.action ?? 'OK'}}</button>
</div>
