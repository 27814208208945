import {HttpErrorResponse} from '@angular/common/http';

export function httpErrorMessageHandler(error: HttpErrorResponse) {

  if (error.error instanceof ErrorEvent) {
    // A client-side or network error occurred. Handle it accordingly.
    return `Si è verificato un errore: ${error.error.message}`;
  } else {
    // The backend returned an unsuccessful response code.
    return `Il server ha risposto con codice ${error.status}<br>Messaggio: ${error.error.error.type}<br>${error.error.error.description}`;
  }
}
